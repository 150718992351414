import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import {
  Hero,
  OutrosInvestimentos,
  NovidadesInvestimentos,
  FaqSection,
} from './sections/_index'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaDebentures } = qrcodeInvestimentsData

function Debentures () {
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)
  const domReady = useDomReady()

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaDebentures.qrcode}
        asFilledLink={qrCodeAberturaDeContaDebentures.deeplink}
      />
    </Modal>
  )
  return (
    <Layout pageContext={pageContext}>
      {cardsModal}
      <Hero setIsModalOpen={setIsModalOpen} />
      <OutrosInvestimentos />
      <NovidadesInvestimentos />
      <FaqSection />
    </Layout>
  )
}

export default Debentures
